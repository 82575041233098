<template>
  <div>
    <ca-header
      heading="Werkstatt bearbeiten"
      :column-layout="columnLayout"
      show-column-layout
      :loading="loading"
      @columnLayout="columnLayout = $event"
    >
      <template #buttonSpace>
        <button
          class="btn btn-primary mr-2"
          @click="save()"
        >
          Speichern <i
            v-if="safePending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
      </template>
      <template #alert>
        <ca-alert
          v-if="saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #customLoading>
        <ca-alert
          variant="info"
          spin-icon
          rounded
          icon="circle-notch"
          message="Lade Werkstattsdaten..."
        />
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout}"
        >
          <div class="row">
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <partner-form
                class="mb-3"
                :workshop="workshop"
              />
            </div>
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <partner-extra-address-form
                class="mb-3"
                :workshop="workshop"
              />
              <partner-info-form
                class="mb-3"
                :workshop="workshop"
              />
              <bank-data-form
                class="mb-3"
                :bank-details-list="bankDetailsList"
                :edit-mode="true"
              />
            </div>
          </div>
          <div
            v-if="!loading"
            class="form-row justify-content-end"
          >
            <button
              class="btn btn-primary mr-2"
              @click="save()"
            >
              Speichern <i
                v-if="safePending"
                class="fas fa-circle-notch fa-spin"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import PartnerForm from '@/components/Forms/Workshop/PartnerForm.vue'
import PartnerExtraAddressForm from '@/components/Forms/Workshop/PartnerExtraAddressForm.vue'
import PartnerInfoForm from '@/components/Forms/Workshop/PartnerInfoForm.vue'
import BankDataForm from '@/components/Forms/Bank/BankDataForm.vue'
import CaAlert from '@/components/Alert.vue'
import iban from 'iban'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'WorkshopEdit',
  components: {
    PartnerForm,
    CaHeader,
    PartnerExtraAddressForm,
    PartnerInfoForm,
    BankDataForm,
    CaAlert
  },
  data () {
    return {
      workshop: null,
      bankDetailsList: [],
      loading: false,
      error: null,
      columnLayout: false,
      safePending: false,
      saveError: null,
      saveErrorMessage: null,
      saveSuccess: false,
      showFormInvalidHint: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        } else if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.saveError = null
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        this.workshop.information.bankId = []
        await this.saveBankDetails()
        await this.workshop.save()
        this.saveSuccess = true
      } catch (e) {
        console.log(e)
        this.saveErrorMessage = e.message
        this.saveError = e
      } finally {
        this.savePending = false
        if (this.saveSuccess) {
          this.$router.push({ path: '/workshop/' + this.$route.params.workshopId })
        }
      }
    },
    async saveBankDetails () {
      for (const bank of this.bankDetailsList) {
        if (iban.isValid(bank.IBAN) || !bank.IBAN) {
          const savedBank = await bank.save()
          this.workshop.information.bankId.push(savedBank._id)
        } else {
          throw new Error(`${bank.VWZ}: Bitte IBAN überprüfen`)
        }
      }
    },
    async fetchData () {
      try {
        this.loading = true
        const workshop = await this.$store.dispatch('workshops/get', this.$route.params.workshopId)
        const bankDetailsList = await this.getBankAccounts(workshop)
        this.workshop = workshop.clone()
        this.bankDetailsList = [...bankDetailsList]
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async getBankAccounts (workshop) {
      const bankDetailsList = []
      if (workshop.information.bankId.length > 0) {
        for (const id of workshop.information.bankId) {
          bankDetailsList.push(await this.$store.dispatch('bank-account/get', id))
        }
      } else {
        bankDetailsList.push(new this.$FeathersVuex.api.BankAccount())
      }
      return bankDetailsList
    }
  }
}
</script>
